import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Form,
  Input,
  notification,
  Select,
  Spin,
  Switch,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { RootState } from "../../store/store";
import {
  categoriasDeEmpresa,
  portesDeEmpresa,
} from "../../constants/enterprises";
import {
  setAddress,
  setBasicData,
} from "../../store/updateStartUpSlice";
import {
  clearStringOnlyNumbers,
  convertMoneyInputMask,
  inputMaskCEP,
  inputMaskCPFCNPJ,
  inputMaskTELWithDDD,
} from "../../libs/masks";
import {
  isValidUrl,
  validaEmail,
  validarCNPJ,
  validateBrazilianPhoneNumber,
  validateCEP,
  validateDateBeforeToday,
} from "../../services/validation.service";
import { AddressService } from "../../services/apis/addressService";
import { FilesServices } from "../../services/apis/filesServices";

import styles from "./EnterpriseForm.module.scss";
import RegistrationFormatter from "./confirm/formatter";
import { RegistrationService } from "../../services/apis/registrationSevice";
import { NotificationType } from "../../types/commonTypes";
import { StartUpService } from "../../services/apis/startUpService";
import { ButtonCustom } from "../../components/customButtom/buttomCustom";
import { Check, CornerDownLeft, StepBack, X } from "lucide-react";
import { IStartUpTypes } from "../../types/startUpTypes";
import { ProfileServices } from "../../services/apis/profileServices";
import { UploadFileStatus } from "antd/lib/upload/interface";

const { Option } = Select;
interface IAddresProps {
    id: number;
    sigla: string;
    nome: string;
    regiao?: {
      id: number;
      sigla: string;
      nome: string;
    };
}

interface IFileProps {
  id: number;
  created_at: string;
  name_innovation: string;
  id_innovation: string;
  id_bucket: string;
  name_document: string;
  url_file: string;
  size_file: string;
  type_file: string;
  id_enterprise_owner: string;
}

interface IFileProps {
  uid: string;
  name: string;
  status?: string;
  url?: string;
  // Add other properties as needed
}

export function Details() {
  const [dataDetails, setDataDetails] = useState<IStartUpTypes>()
  const [loadingSwitch, setLoadingSwitch] = useState(false);
  const [loadingSwitchPermission, setLoadingSwitchPermission] = useState(false);
  const [ufs, setUfs] = useState<IAddresProps[]>([]);
  const [files, setFiles] = useState<any[]>();
  const [fileList, setFileList] = useState<UploadFile<any>[]>([]);

  const currentWindowWidth = window.innerWidth;
  const userToken = Cookies.get("access_token");
  const customIdEnterprise = uuidv4();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params
  const goBack = () => {
    navigate(-1);
  };
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const formatted = new RegistrationFormatter();
  const registrationService = new RegistrationService();
  const startUpService = new StartUpService();
  const profileService = new ProfileServices();

  const startUpBasicData = useSelector(
    (state: RootState) => state.updateStartUpSlice.basicData
  );
  const startUpAddressData = useSelector(
    (state: RootState) => state.updateStartUpSlice.address
  );

  const addressService = new AddressService();
  const fileService = new FilesServices();

  const [loading, setLoading] = React.useState(false);

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    description: string
  ) => {
    api[type]({
      message: title,
      description: description,
    });
  };

  const getAllUfs = async () => {
    try {
      const response = await addressService.getUfs();
      setUfs(response);
    } catch (error) {
      return;
    }
  };

  const getStartUp = async () => {
    try {
        if (!id) {
            throw new Error('Nenhuma instituição encontrada!')
        }
        const readStartUp = await startUpService.readStartupById(id?.toString())
        form.setFieldsValue({
            cnpj: inputMaskCPFCNPJ(readStartUp.cnpj),
            cei: readStartUp.cei,
            nome_fantasia: readStartUp.nome_fantasia,
            razao_social: readStartUp.razao_social,
            categoria: readStartUp.categoria,
            data_fundacao: readStartUp.data_fundacao,
            numero_funcionarios: readStartUp.numero_funcionarios,
            cnae: readStartUp.cnae,
            valor_pagamento: readStartUp.valor_pagamento,
            email: readStartUp.contatos.email,
            phone: inputMaskTELWithDDD(readStartUp.contatos.telefone),
            cellphone: inputMaskTELWithDDD(readStartUp.contatos.celular),
            cep: inputMaskCEP(readStartUp.endereco.cep),
            street: readStartUp.endereco.street,
            number: readStartUp.endereco.number,
            complemento: readStartUp.endereco.complemento,
            neighborhood: readStartUp.endereco.neighborhood,
            city: readStartUp.endereco.city,
            state: readStartUp.endereco.state,
            nome_responsavel: readStartUp.owners.nome_responsavel,
            cargo_responsavel: readStartUp.owners.cargo,
            porte: readStartUp.porte
        })
        setDataDetails(readStartUp);
        setFiles(readStartUp.documents);
    } catch (error: any) {
        openNotificationWithIcon(
            'error',
            'Erro',
            error.message ?? 'Não foi possivel processar sua solicitação!'
        )
    }
  }

  const getActionUrl = (file: any) => {
    // Verificar o tipo de arquivo para decidir a rota
    if (file.type.startsWith("image/")) {
      return "https://orca-app-so9hc.ondigitalocean.app/files/upload"; // Staging
      // return "http://localhost:4001/files/upload"; // Local
    } else {
      return "https://orca-app-so9hc.ondigitalocean.app/files/upload/document"; // Staging
      // return "http://localhost:4001/files/upload/document"; // Local
    }
  };

  const handleUpload: UploadProps = {
    action: getActionUrl,

    headers: {
      Authorization: `Bearer ${userToken}`,
      'X-Custom-Header': dataDetails?.id_initial_create_enterprise ?? 'not found',
    },

    onChange(info) { 
      // Atualizar fileList imediatamente para refletir o status de upload.
      setFileList(info.fileList.map(file => ({
        ...file,
        url: file.response?.url // Certifique-se de que o URL é acessível no objeto de resposta
      })));
    
      if (info.file.status === 'done') {
        openNotificationWithIcon("success", "Arquivo enviado", `${info.file.name} enviado com sucesso`);
      } else if (info.file.status === 'error') {
        openNotificationWithIcon("error", "Falha no envio", `${info.file.name} falhou ao enviar`);
      }
    },

    async onRemove(file: UploadFile) {
      // A suposição é que a informação necessária para deletar o arquivo está dentro de `file.response`
      const id = file.uid; // Adapte conforme a estrutura de sua resposta
      if (!id) {
        openNotificationWithIcon("error", "Erro ao deletar", "Não foi possível deletar o arquivo [id não encontrado]");
        return;
      }
    
      await fileService.deleteFile(id).then(() => {
        setFileList(prevFiles => prevFiles.filter(f => f.uid !== file.uid));
        openNotificationWithIcon("success", "Arquivo deletado", "Arquivo deletado com sucesso");
      }).catch(error => {
        openNotificationWithIcon("error", "Erro ao deletar", "Não foi possível deletar o arquivo");
      });
    }
  };

  const handleChangeAddress = (
    event: React.ChangeEvent<HTMLInputElement>,
    target: { name: string }
  ) => {
    const { value } = event.target;
    const { name } = target;
    const key = name;
    if (key === "cep") {
      if (value.length === 8) {
        setLoading(true);
        handleAddressByCep(clearStringOnlyNumbers(value));
        form.setFieldsValue({
          cep: inputMaskCEP(value),
        });
      }

      dispatch(setAddress({ [name]: clearStringOnlyNumbers(value) }));
      return;
    }

    dispatch(setAddress({ [name]: value }));
  };

  const handleAddressByCep = async (cep: string) => {
    try {
      const response = await addressService.getAddressByCep(cep);
      dispatch(
        setAddress({
          street: response.logradouro,
          neighborhood: response.bairro,
          city: response.localidade,
          state:
          ufs?.find((option) => option.sigla === response.uf)?.nome ??
            "state not found",
        })
      );

      form.setFieldsValue({
        street: response.logradouro,
        neighborhood: response.bairro,
        city: response.localidade,
        state:
        ufs?.find((option) => option.sigla === response.uf)?.nome ??
          "state not found",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(
        setAddress({
          street: "",
          neighborhood: "",
          city: "",
          state: "",
        })
      );
      return;
    }
  };

  const handleChangeFilterState = (value: string) => {
    dispatch(setAddress({ state: value }));
  };

  const handleFinish = async (values: any) => {
    setLoading(true);
    try {
      const formattedData = formatted.startUpUpdated({
        ...values,
      });
      if (!dataDetails?.id) {
        openNotificationWithIcon(
          'error',
          'Erro',
          'Erro ao atualizar empresa [id]'
        )
        return;
      }
      await registrationService.upDateStartup(dataDetails?.id, formattedData);
      setLoading(false);
      openNotificationWithIcon(
        "success",
        "Sucesso",
        "Dados da empresa atualizados com sucesso!"
      );
      setTimeout(() => {
        goBack();
      }, 1000);
    } catch (error: any) {
      setLoading(false);
      openNotificationWithIcon(
        "error",
        "Erro",
        error['message'] ?? "Erro ao cadastrar empresa"
      );
      return;
    }
  };

  const updateStatus = async (id: number, status: string) => {
    setLoadingSwitch(true);
    try {
      await registrationService.upDateStartup(id, { status: status });
      getStartUp();
      setLoadingSwitch(false);
    } catch (error: any) {
      setLoadingSwitch(false);
      openNotificationWithIcon(
        'error',
        'Erro ao atualizar status',
        error.message ?? 'Não foi possivel atualizar o status da instituição!'
      )
    }
  };

  const updatePermissionEvents = async (status: boolean) => {
    setLoadingSwitchPermission(true);
    try {
      if (!dataDetails?.cnpj) {
        openNotificationWithIcon(
            'error',
            'Erro ao atualizar instituição!',
            'Não foi possivel atualizar essa instituição, por favor verifique os dados e tente novamente'
        )
        return;
      }
      await profileService.upDatePermissionEventsByOwner(dataDetails?.cnpj, { events_register_permission: status });
      getStartUp();
      setLoadingSwitchPermission(false);
    } catch (error: any) {
        setLoadingSwitchPermission(false);
      openNotificationWithIcon(
        'error',
        'Erro ao atualizar status',
        error.message ?? 'Não foi possivel atualizar o status da instituição!'
      )
    }
  };

  useEffect(() => {
    getStartUp();
    getAllUfs();
    dispatch(setBasicData({ id_initial_create_enterprise: customIdEnterprise }));
  }, []);


  const mapIFilePropsToUploadFile = (file: any): UploadFile<any> => ({
    uid: file.id,
    name: file.name_document,
    status: 'done' as UploadFileStatus,
    url: file.url_file,
    type: file.type_file,
    fileName: file.name_document,
    // Add other properties as needed
  });

  useEffect(() => {
    if (!files) {
      return;
    }
    const mappedFiles: UploadFile<any>[] = files?.map(mapIFilePropsToUploadFile);
    setFileList(mappedFiles);
  }, [dataDetails]);

  return (
    <Form layout="vertical" form={form} onFinish={handleFinish}>
      {contextHolder}
      <Spin
        tip="Loading"
        size="large"
        className={styles.spinLoading}
        spinning={loading}
      >
        <ButtonCustom 
            label="Retornar"
            onClick={goBack}
            style={{
                maxWidth: '15%',
                height: '50px',
                marginLeft: '16px'
            }}
            icon={CornerDownLeft}
        />
        <div className={styles.formContainer}>
          <section className={styles.sectionBasicData}>
            <div className={styles.subSection}>
              <div className={styles.sectionTitle}>Dados Cadastrais</div>
              <div className={styles.inputGroupFlex}>
                <Form.Item
                  label="CNPJ"
                  name="cnpj"
                  rules={[
                    {
                      required: true,
                      message: "Campo CNPJ é obrigatório",
                      validator: (rule, value) => {
                        if (validarCNPJ(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject((rule.message = "CNPJ inválido"));
                      },
                    },
                  ]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "70%" }
                      : { width: "100%" }
                  }
                >
                  <Input
                    onChange={(e) =>
                      form.setFieldsValue({
                        cnpj: inputMaskCPFCNPJ(e.target.value),
                      })
                    }
                  />
                </Form.Item>
                <Form.Item
                  label="CEI"
                  name="cei"
                  style={
                    currentWindowWidth > 1024
                      ? { width: "29%" }
                      : { width: "100%" }
                  }
                >
                  <Input />
                </Form.Item>
              </div>
              <div className={styles.inputGroup}>
                <Form.Item label="Nome Fantasia" name="nome_fantasia">
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Razão Social"
                  name="razao_social"
                  rules={[
                    {
                      required: true,
                      message: "Campo Razão Social é obrigatório",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className={styles.inputGroupFlex}>
                <Form.Item
                  label="Porte"
                  name="porte"
                  rules={[{ required: false }]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "33%" }
                      : { width: "100%" }
                  }
                >
                  <Select placeholder="Selecione">
                    {portesDeEmpresa.map((porte) => (
                      <Option key={porte} value={porte}>
                        {porte}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Categoria"
                  name="categoria"
                  rules={[
                    {
                      required: true,
                      message: "Campo Categoria é obrigatório",
                    },
                  ]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "33%" }
                      : { width: "100%" }
                  }
                >
                  <Select placeholder="Selecione">
                    {categoriasDeEmpresa.map((categoria) => (
                      <Option key={categoria} value={categoria}>
                        {categoria}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Data de Fundação"
                  name="data_fundacao"
                  rules={[
                    {
                      required: true,
                      message: "Campo Data de Fundação é obrigatório",
                      validator: (rule, value) => {
                        if (validateDateBeforeToday(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          (rule.message =
                            "Data de Fundação não pode ser maior que a data atual")
                        );
                      },
                    },
                  ]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "33%" }
                      : { width: "100%" }
                  }
                >
                  <Input type="date" />
                </Form.Item>
              </div>
              <div className={styles.inputGroupFlex}>
                <Form.Item
                  label="Num. de Funcionários"
                  name="numero_funcionarios"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="CNAE"
                  name="cnae"
                  rules={[{ required: false }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Vlr. Pagamento"
                  name="valor_pagamento"
                  rules={[{ required: false }]}
                >
                  <Input
                    onChange={(e) => {
                      form.setFieldsValue({
                        valor_pagamento: convertMoneyInputMask(e.target.value),
                      });
                    }}
                  />
                </Form.Item>
              </div>
            </div>
            <div className={styles.subSection}>
              <div className={styles.sectionTitle}>
                Responsável pela empresa
              </div>
              <Form.Item
                label="Nome do Responsável"
                name="nome_responsavel"
                rules={[{ required: false }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Cargo do Responsável"
                name="cargo_responsavel"
                rules={[{ required: false }]}
              >
                <Input />
              </Form.Item>
            </div>
          </section>
          <section className={styles.section}>
          <div className={styles.subSection}>
            <div className={styles.sectionTitle}>Permissões</div>
            <div className={styles.inputGroupFlex} style={{ justifyContent: 'flex-start', gap: '2rem'}}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.5rem'
                    }}      
                >
                    <label>Status</label>
                    <Switch
                        checkedChildren="Ativo"
                        unCheckedChildren="Inativo"
                        defaultChecked={dataDetails?.status === "active" ? true : false}
                        loading={loadingSwitch}
                        value={dataDetails?.status === "active" ? true : false}
                        onChange={(checked) => {
                            if (typeof dataDetails?.id !== 'number') {
                                openNotificationWithIcon(
                                    'error',
                                    'Erro ao atualizar status!',
                                    'Formato do id incompativel'
                                )
                                return;
                            }
                            updateStatus(dataDetails?.id, checked ? "active" : "inactive")
                        }}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.5rem',
                    }}      
                >
                    <label>Permissão para cadastrar enventos</label>
                    <Switch
                        checkedChildren="Ativo"
                        unCheckedChildren="Inativo"
                        defaultChecked={dataDetails?.events_register_permission}
                        loading={loadingSwitchPermission}
                        value={dataDetails?.events_register_permission}
                        onChange={(checked) =>
                            updatePermissionEvents(checked)
                        }
                        style={{
                            width: '30%'
                        }}
                    />
                </div>
            </div>
          </div>
            <div className={styles.subSection}>
              <div className={styles.sectionTitle}>Contatos</div>
              <div className={styles.inputGroupFlex}>
                <Form.Item
                  label="Telefone"
                  name="phone"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "49%" }
                      : { marginTop: "10px" }
                  }
                >
                  <Input
                    onChange={(e) =>
                      form.setFieldsValue({
                        phone: inputMaskTELWithDDD(e.target.value),
                      })
                    }
                  />
                </Form.Item>
                <Form.Item
                  label="Celular"
                  name="cellphone"
                  rules={[
                    {
                      required: true,
                      message: "Campo Celular é obrigatório",
                      validator: (rule, value) => {
                        if (
                          validateBrazilianPhoneNumber(
                            clearStringOnlyNumbers(value)
                          )
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          (rule.message = "Celular inválido")
                        );
                      },
                    },
                  ]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "49%" }
                      : { marginTop: "10px" }
                  }
                >
                  <Input
                    onChange={(e) =>
                      form.setFieldsValue({
                        cellphone: inputMaskTELWithDDD(e.target.value),
                      })
                    }
                  />
                </Form.Item>
              </div>
              <div className={styles.inputGroup}>
                <Form.Item
                  label="Site"
                  name="site"
                  rules={[
                    {
                      required: false,
                      validator: (rule, value) => {
                        if (isValidUrl(value) || !value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          (rule.message =
                            "Site é obrigatório, verifique se a URL está correta")
                        );
                      },
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className={styles.inputGroup}>
                <Form.Item
                  label="E-mail"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Campo E-mail é obrigatório",
                      validator: (rule, value) => {
                        if (validaEmail(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          (rule.message = "E-mail inválido")
                        );
                      },
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
            </div>
            <div className={styles.subSection}>
              <div className={styles.sectionTitle}>Endereço</div>
              <div className={styles.inputGroupFlex}>
                <Form.Item
                  label="CEP"
                  name="cep"
                  hasFeedback
                  validateStatus={loading ? "validating" : ""}
                  rules={[
                    {
                      required: true,
                      message: "Campo CEP é obrigatório",
                      validator: (rule, value) => {
                        if (validateCEP(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject((rule.message = "CEP inválido"));
                      },
                    },
                  ]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "25%" }
                      : { width: "100%" }
                  }
                >
                  <Input
                    onChange={(event) => {
                      handleChangeAddress(event, { name: "cep" });
                    }}
                    value={inputMaskCEP(startUpAddressData.cep)}
                  />
                </Form.Item>
                <Form.Item
                  label="Logradouro"
                  name="street"
                  hasFeedback
                  validateStatus={loading ? "validating" : ""}
                  rules={[
                    {
                      required: true,
                      message: "Campo Logradouro é obrigatório",
                    },
                  ]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "57%" }
                      : { width: "100%" }
                  }
                >
                  <Input
                    onChange={(event) => {
                      handleChangeAddress(event, { name: "street" });
                    }}
                    value={startUpAddressData.street}
                  />
                </Form.Item>
                <Form.Item
                  label="Número"
                  name="number"
                  rules={[
                    { required: true, message: "Campo Número é obrigatório" },
                  ]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "18%" }
                      : { width: "100%" }
                  }
                >
                  <Input
                    onChange={(event) => {
                      handleChangeAddress(event, { name: "number" });
                    }}
                    value={startUpAddressData.number}
                  />
                </Form.Item>
              </div>
              <div className={styles.inputGroup}>
                <Form.Item label="Complemento" name="complemento">
                  <Input
                    onChange={(event) => {
                      handleChangeAddress(event, { name: "complemento" });
                    }}
                    value={startUpAddressData.complemento}
                  />
                </Form.Item>
              </div>
              <div className={styles.inputGroupFlex}>
                <Form.Item
                  label="Bairro"
                  name="neighborhood"
                  hasFeedback
                  validateStatus={loading ? "validating" : ""}
                  rules={[
                    { required: true, message: "Campo Bairro é obrigatório" },
                  ]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "33%" }
                      : { width: "100%" }
                  }
                >
                  <Input
                    onChange={(event) => {
                      handleChangeAddress(event, { name: "neighborhood" });
                    }}
                    value={startUpAddressData.neighborhood}
                  />
                </Form.Item>
                <Form.Item
                  label="Cidade"
                  name="city"
                  hasFeedback
                  validateStatus={loading ? "validating" : ""}
                  rules={[
                    { required: true, message: "Campo Cidade é obrigatório" },
                  ]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "46%" }
                      : { width: "100%" }
                  }
                >
                  <Input
                    onChange={(event) => {
                      handleChangeAddress(event, { name: "city" });
                    }}
                    value={startUpAddressData.city}
                  />
                </Form.Item>
                <Form.Item
                  label="UF"
                  name="state"
                  hasFeedback
                  validateStatus={loading ? "validating" : ""}
                  rules={[
                    { required: true, message: "Campo UF é obrigatório" },
                  ]}
                  style={
                    currentWindowWidth > 1024
                      ? { width: "20%" }
                      : { width: "100%" }
                  }
                >
                  <Select
                    onChange={handleChangeFilterState}
                    value={startUpAddressData.state}
                    placeholder="Selecione"
                  >
                    {ufs?.map((option) => (
                      <Option key={option.sigla} value={option.nome}>
                        {option.sigla}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
          </section>
          <section className={styles.section}>
            <div className={styles.subSection}>
              <div className={styles.sectionTitle}>Anexos</div>
              <Upload
                {...handleUpload}
                listType="picture"
                multiple
                fileList={fileList}
              >
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </div>
          </section>
        </div>
        <div className={styles.boxButtom}>
          <Form.Item className={styles.formButton}>
            <button type="button" onClick={goBack}>
              Voltar
            </button>
          </Form.Item>
          <Form.Item className={styles.formButton}>
            <Button type="primary" htmlType="submit">
              Atualizar
            </Button>
          </Form.Item>
        </div>
        <div className={styles.contentSpin} />
      </Spin>
    </Form>
  );
}
