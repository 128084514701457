import { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Descriptions,
  FloatButton,
  Modal,
  notification,
  Row,
  Select,
} from "antd";
import {
  CalendarCheck,
  ChevronLeft,
  ChevronRight,
  UserRound,
  Filter,
  Link,
  Link2Icon,
  LinkIcon,
  UserRoundPlus,
} from "lucide-react";
import CryptoJS from "crypto-js";

import FullCalendar from "@fullcalendar/react";
import CalendarOptions from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import multiMonthPlugin from "@fullcalendar/multimonth";
import ptBrLocale from "@fullcalendar/core/locales/pt-br";
import moment from "moment";
import { Calendar } from "@fullcalendar/core";

import { HeaderFilters } from "../../components/header_filters/headerFilters";
import { NewEvent } from "./newEvent";
import { EventDetails } from "./EventDetails";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { EventsServices } from "../../services/apis/eventsServices";
import { Idate, IEvent } from "../../types/eventTypes";
import { UpdateEvent } from "./updateEvent";

import styles from "./events.module.scss";
import Cookies from "js-cookie";
import { ButtonCustom } from "../../components/customButtom/buttomCustom";
import ShareOptions from "../../components/shareOptions/shareWhatsOrEmail";
import { NotificationType } from "../../types/commonTypes";

interface IEditable {
  visible: boolean;
  eventId: number | null;
}

interface IShareOptions {
  visible: boolean;
  title: string;
  pathUrl: string;
  textWhats: string;
  handleEmailChange: (email: string, user?: string) => void;
}

export function EventsPage() {
  const calendarRef = useRef<FullCalendar>(null);
  const eventService = new EventsServices();
  const userProfile = Cookies.get("profile");
  const user = JSON.parse(userProfile || "{}");
  const [api, contextHolder] = notification.useNotification();
  const [finalEvents, setFinalEvents] = useState<IEvent[]>([]);
  const [event, setEvent] = useState<any>();
  const [newEventVisible, setNewEventVisible] = useState(false);
  const [eventDetailsVisible, setEventDetailsVisible] = useState(false);
  const [editable, setEditable] = useState<IEditable>({
    visible: false,
    eventId: null,
  });
  const [visibleModalFiltered, setVisibleModalFiltered] = useState(false);
  const [currentView, setCurrentView] = useState("dayGridMonth");
  const [shareOptionsVisible, setShareOptionsVisible] = useState<IShareOptions>(
    {
      visible: false,
      title: "",
      pathUrl: "",
      textWhats: "",
      handleEmailChange: () => {},
    }
  );
  const [loadingInvite, setLoadingInvite] = useState(false);
  const styleBySubDomain = useSelector((state: RootState) => state.styleSlice);

  const rolesPermissionInvites = ["ADM", "innovation"];
  const rolesRefPermissionInvites = ["master", "innovation"];
  const rolesPermissionEdit = ["ADM", "innovation", "startup", "EVENTOS"];

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    description: string
  ) => {
    api[type]({
      message: title,
      description: description,
    });
  };

  const getFormattedDateRange = (dates: Idate[]) => {
    if (dates.length === 0) return "";

    const sortedDates = dates.sort(
      (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime()
    );
    const firstDate = moment(sortedDates[0].start);
    const lastDate = moment(sortedDates[sortedDates.length - 1].end);

    if (firstDate.isSame(lastDate, "day")) {
      // Se o evento começa e termina no mesmo dia
      return firstDate.format("DD [de] MMMM [de] YYYY");
    } else {
      // Se o evento abrange múltiplos dias
      return `de ${firstDate.format("DD [de] MMMM")} a ${lastDate.format(
        "DD [de] MMMM [de] YYYY"
      )}`;
    }
  };

  const getEvents = async () => {
    try {
      const response = await eventService.getAllEvents();
      const newEvents = response.flatMap((event: IEvent) =>
        event.dates.map((date: Idate) => ({
          title: event.title,
          start: moment(date.start).toDate(),
          end: moment(date.end).toDate(),
          description: event.description,
          movement: event.movement,
          location: event.location,
          accessLink: event.access_link,
          files: event.files,
          user_id: event.user_created_id,
          url_images_events: event.url_images_events,
          id_initial: event.id_initial,
          event_id: event.id,
          owner: event.owner,
          dateRange: getFormattedDateRange(event.dates),
        }))
      );
      setFinalEvents(newEvents); // Atualiza o estado de finalEvents
      return;
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleNewEvent = () => {
    setNewEventVisible(true);
  };

  const handleViewChange = (value: string) => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      calendarApi.changeView(value);
      setCurrentView(value);
      setVisibleModalFiltered(false);
    }
  };

  const handlePrev = () => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      calendarApi.prev();
      setVisibleModalFiltered(false);
    }
  };

  const handleNext = () => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      calendarApi.next();
      setVisibleModalFiltered(false);
    }
  };

  const handleToday = () => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      calendarApi.today();
      setVisibleModalFiltered(false);
    }
  };

  const showShareModal = ({
    title,
    pathUrl,
    textWhats,
    handleEmailChange,
  }: IShareOptions) => {
    setShareOptionsVisible({
      visible: true,
      title,
      pathUrl,
      textWhats,
      handleEmailChange,
    });
  };

  const shareEventEmail = async (email: string) => {
    setLoadingInvite(true);
    try {
      const invitation = await eventService.invitationEvent(email);
      openNotificationWithIcon(
        "success",
        "Convite enviado",
        "Convite enviado com sucesso!"
      );
      setLoadingInvite(false);
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Erro ao enviar convite",
        "Erro ao enviar convite por e-mail"
      );
      setLoadingInvite(false);
    }
  };

  const inviteEditEvent = async (email: string, user: any) => {
    setLoadingInvite(true);
    try {
      const encryptedUser = JSON.parse(user);
      const invitation = await eventService.inviteEditEvent(
        email,
        encryptedUser[0].id_auth
      );
      openNotificationWithIcon(
        "success",
        "Convite enviado",
        "Convite enviado com sucesso!"
      );
      setLoadingInvite(false);
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Erro ao enviar convite",
        "Erro ao enviar convite por e-mail"
      );
      setLoadingInvite(false);
    }
  };

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <>
      {user[0]?.events_register_permission &&
        rolesPermissionEdit.includes(user[0]?.role) && (
          <HeaderFilters changeButton={handleNewEvent} text="novo evento" />
        )}
      {contextHolder}
      <div
        className={`${
          styleBySubDomain.backGroundColorMenu === "#6E2DB0"
            ? styles.calendarContainerElume
            : styles.calendarContainerBase
        }`}
      >
        <FullCalendar
          ref={calendarRef}
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            multiMonthPlugin,
          ]}
          headerToolbar={{
            left: window.innerWidth > 768 ? "prev,next today" : "",
            center: "title",
            right:
              window.innerWidth > 768
                ? "multiMonthYear,dayGridMonth,timeGridWeek,timeGridDay"
                : "myCustomButton",
          }}
          customButtons={{
            myCustomButton: {
              text: "Filtros",
              click: function () {
                setVisibleModalFiltered(true);
              },
            },
          }}
          eventClick={(info) => {
            setEvent(info);
            setEventDetailsVisible(true);
          }}
          eventColor={styleBySubDomain.backGroundColorMenu}
          locale={ptBrLocale}
          events={finalEvents}
          selectable
          nowIndicator
          expandRows
          slotDuration="01:00:00"
          allDaySlot={false}
          initialView="dayGridMonth"
        />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "1rem",
          marginTop: "1rem",
        }}
      >
        {window.innerWidth > 1024 && (
          <ButtonCustom
            style={{
              width: "20%",
              marginTop: "1rem",
              padding: "1.5rem",
              borderRadius: "1.5rem",
            }}
            icon={Link}
            label="Enviar link publico"
            loading={loadingInvite}
            onClick={() => {
              showShareModal({
                visible: true,
                title: "Compartilhar link do evento",
                pathUrl: `${window.location.origin}/public-events`,
                textWhats:
                  "Olá, tudo bem? Estou te convidando para visualizar nossos eventos, acesse o link abaixo e participe conosco!",
                handleEmailChange: (email) => {
                  shareEventEmail(email);
                },
              });
            }}
          />
        )}
        {rolesPermissionInvites.includes(user[0].role) &&
          rolesRefPermissionInvites.includes(user[0].role_ref) &&
          window.innerWidth > 1024 && (
            <ButtonCustom
              label="Enviar link para cadastro de eventos"
              loading={loadingInvite}
              onClick={() => {
                showShareModal({
                  visible: true,
                  title: "Compartilhar link de cadastro de eventos",
                  pathUrl: `https://orca-app-so9hc.ondigitalocean.app/events-public/redirect/${user[0]?.id_auth}`,
                  textWhats:
                    "Olá, tudo bem? Estou te convidando para se cadastrar e criar eventos conosco, acesse o link abaixo e participe conosco!",
                  handleEmailChange: (email, user) => {
                    inviteEditEvent(email, user);
                  },
                });
              }}
              style={{
                width: "30%",
                height: "fit-content",
                marginTop: "1rem",
                padding: "0.5rem",
                borderRadius: "1.5rem",
                textWrap: "wrap",
              }}
              icon={CalendarCheck}
            />
          )}
        {window.innerWidth <= 1024 && (
          <FloatButton.Group
            shape="square"
            style={{
              bottom: "1rem",
              left: "2.5rem",
            }}
          >
            <FloatButton
              icon={<Link2Icon />}
              onClick={() => {
                showShareModal({
                  visible: true,
                  title: "Compartilhar link do evento",
                  pathUrl: `${window.location.origin}/public-events`,
                  textWhats:
                    "Olá, tudo bem? Estou te convidando para visualizar nossos eventos, acesse o link abaixo e participe conosco!",
                  handleEmailChange: (email) => {
                    shareEventEmail(email);
                  },
                });
              }}
              tooltip="Enviar link publico"
            />
            <FloatButton
              icon={<UserRoundPlus />}
              onClick={() => {
                showShareModal({
                  visible: true,
                  title: "Compartilhar link de cadastro de eventos",
                  pathUrl: `https://orca-app-so9hc.ondigitalocean.app/events-public/redirect/${user[0].id_auth}`,
                  textWhats:
                    "Olá, tudo bem? Estou te convidando para se cadastrar e criar eventos conosco, acesse o link abaixo e participe conosco!",
                  handleEmailChange: (email, user) => {
                    inviteEditEvent(email, user);
                  },
                });
              }}
              tooltip="Enviar link para cadastro de eventos"
            />
          </FloatButton.Group>
        )}
      </div>
      <ShareOptions
        visible={shareOptionsVisible.visible}
        onClose={() =>
          setShareOptionsVisible({
            visible: false,
            title: "",
            pathUrl: "",
            textWhats: "",
            handleEmailChange: () => {},
          })
        }
        shareData={shareOptionsVisible}
      />
      <Modal
        open={visibleModalFiltered}
        onCancel={() => setVisibleModalFiltered(false)}
        title="Escolha a forma de visualização"
        okButtonProps={{
          style: {
            display: "none",
          },
        }}
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
      >
        <Descriptions.Item>
          Selecione o intervalo de visualização desejado.
        </Descriptions.Item>
        <Row
          gutter={8}
          style={{
            marginTop: "0.5rem",
            marginBottom: "1rem",
          }}
        >
          <Col>
            <Button
              type="primary"
              icon={<ChevronLeft />}
              onClick={handlePrev}
            ></Button>
          </Col>
          <Col>
            <Button type="primary" onClick={handleToday}>
              Hoje
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              icon={<ChevronRight />}
              onClick={handleNext}
            ></Button>
          </Col>
        </Row>
        <Descriptions.Item>Formato de visualização:</Descriptions.Item>
        <Row
          gutter={8}
          style={{
            marginTop: "0.5rem",
            marginBottom: "0.75rem",
          }}
        >
          <Col span={24}>
            <Select
              defaultValue={currentView}
              style={{ width: "100%" }}
              onChange={handleViewChange}
            >
              <Select.Option value="dayGridMonth">Mês</Select.Option>
              <Select.Option value="timeGridWeek">Semana</Select.Option>
              <Select.Option value="timeGridDay">Dia</Select.Option>
              <Select.Option value="multiMonthYear">Ano</Select.Option>
            </Select>
          </Col>
        </Row>
      </Modal>

      <NewEvent
        visible={newEventVisible}
        setVisible={setNewEventVisible}
        getEvents={getEvents}
      />
      <UpdateEvent
        visible={editable.visible}
        setVisible={setEditable}
        idEvent={editable.eventId}
        getEvents={getEvents}
      />
      <EventDetails
        visible={eventDetailsVisible}
        setVisible={setEventDetailsVisible}
        event={event}
        setEditable={setEditable}
        getEvents={getEvents}
      />
    </>
  );
}
