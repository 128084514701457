import { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  notification,
  Spin,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Plus } from "lucide-react";

import moment from "moment";
import { RootState } from "../../store/store";

import Cookies from "js-cookie";

import { generateIdInitial, resetIdInitial } from "../../store/commonSlice";
import { NotificationType } from "../../types/commonTypes";
import { FilesServices } from "../../services/apis/filesServices";
import { Formatters } from "./domains/formatters";
import { DatesWithHours } from "./selectDates";
import styles from "./events.module.scss";
import { EventsServices } from "../../services/apis/eventsServices";

const { TextArea } = Input;

interface NewEventProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  getEvents: () => void;
}

export function NewEvent({
  visible,
  setVisible,
  getEvents,
}: Readonly<NewEventProps>) {
  const currentWidth = window.innerWidth;
  const userToken = Cookies.get("access_token");
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [arrayDates, setArrayDates] = useState<any[]>([""]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const idInitial = useSelector(
    (state: RootState) => state.commonSlice.idInitial
  );
  const [api, contextHolder] = notification.useNotification();

  const filesServices = new FilesServices();

  const imcrementDates = () => {
    setArrayDates([...arrayDates, ""]);
  };

  const removeDate = (index: number) => {
    if (arrayDates.length === 1) {
      return;
    }
    const newArray = arrayDates.filter((_, i) => i !== index);
    setArrayDates(newArray);
  };

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    description: string
  ) => {
    api[type]({
      message: title,
      description: description,
    });
  };

  useEffect(() => {
    dispatch(generateIdInitial());

    return () => {
      // Resetar o id quando o componente desmontar, se necessário
      dispatch(resetIdInitial());
    };
  }, [dispatch]);

  const onFinish = async (values: any) => {
    setLoading(true);
    const formatters = new Formatters();
    const eventService = new EventsServices();
    try {
      const dates: any = [];
      const formattedValues = Object.keys(values);
      for (let i = 0; i < formattedValues.length; i++) {
        if (formattedValues[i].includes("initialDate")) {
          const index = formattedValues[i].split("initialDate")[1];
          const startDate = new Date(
            values[`initialDate${index}`] + "T00:00:00"
          ); // Adiciona T00:00:00 para garantir que a data é criada corretamente

          const startDateTime = new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate(),
            parseInt(values[`initialTime${index}`].split(":")[0]),
            parseInt(values[`initialTime${index}`].split(":")[1])
          );

          const endDateTime = new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate(),
            parseInt(values[`endTime${index}`].split(":")[0]),
            parseInt(values[`endTime${index}`].split(":")[1])
          );

          const date = {
            start: startDateTime.toISOString(),
            end: endDateTime.toISOString(),
          };
          dates.push(date);
        }
      }
      const formattedEvent = formatters.formatEvent({
        ...values,
        id_initial: idInitial,
        dates,
      });
      await eventService.createEvent(formattedEvent);
      setLoading(false);
      openNotificationWithIcon(
        "success",
        "Evento criado",
        "Evento criado com sucesso"
      );
      setVisible(false);
      getEvents();
    } catch (error: any) {
      setLoading(false);
      openNotificationWithIcon(
        "error",
        "Erro ao criar evento",
        `${error.message} - Tente novamente`
      );
    }
  };

  const deleteFile = async (fileName: string) => {
    try {
      const response = await filesServices.deleteAttachment(fileName);
      return response;
    } catch (error) {
      return;
    }
  };

  const handleUpload: UploadProps = {
    // action: 'http://localhost:4001/files/upload/attachments-events', //local
    action:
      "https://orca-app-so9hc.ondigitalocean.app/files/upload/attachments-events", //stage
    headers: {
      Authorization: `Bearer ${userToken}`,
      "X-Custom-Header": idInitial,
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        openNotificationWithIcon(
          "success",
          "Arquivo enviado",
          `${info.file.name} enviado com sucesso`
        );
      } else if (info.file.status === "error") {
        openNotificationWithIcon(
          "error",
          "Falha no envio",
          `${info.file.name} falhou ao enviar`
        );
      }
    },

    onRemove(file: any) {
      deleteFile(file.response.fileName);
    },
  };

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  return (
    <Modal
      title="Criar Evento"
      open={visible}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      onCancel={() => {
        setVisible(false);
      }}
      width={currentWidth < 768 ? "100%" : "50%"}
    >
      {contextHolder}
      <Form
        className={styles.formContainer}
        onFinish={onFinish}
        form={form}
        layout="vertical"
      >
        <Form.Item
          className={styles.formItem}
          label="Título"
          name="title"
          rules={[{ required: true, message: "Campo título é obrigatório" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className={styles.formItem}
          label="Descrição"
          name="description"
          rules={[{ required: true, message: "Campo descrição é obrigatório" }]}
        >
          <TextArea />
        </Form.Item>
        <div className={styles.formRow}>
          <Form.Item
            className={styles.formItem}
            label="Realizador"
            name="movement"
            rules={[
              { required: true, message: "Campo movimento é obrigatório" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            className={styles.formItem}
            label="Local previsto"
            name="location"
          >
            <Input />
          </Form.Item>
        </div>
        <Form.Item
          className={styles.formItem}
          label="Link de acesso"
          name="accessLink"
        >
          <Input />
        </Form.Item>

        <Form.Item
          className={styles.formItem}
          label="Link de Fotos e Vídeos"
          name="url_images_events"
        >
          <Input />
        </Form.Item>

        {arrayDates.map((_, index) => (
          <DatesWithHours key={index} removeDate={removeDate} index={index} />
        ))}
        <Button
          type="primary"
          icon={<Plus />}
          onClick={imcrementDates}
          style={{
            width: 35,
            height: 35,
            marginTop: "-0.5rem",
            marginBottom: "1.5rem",
          }}
        />

        <div>Imagens do evento</div>
        <br />

        <Upload {...handleUpload} multiple listType="picture-card" name="files">
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>

        <div className={styles.formButtonContainer}>
          <Form.Item className={styles.formButton}>
            <button
              type="button"
              onClick={() => {
                setVisible(false);
              }}
            >
              Voltar
            </button>
          </Form.Item>
          <Form.Item className={styles.formButton}>
            <Button type="primary" htmlType="submit">
              Cadastrar
            </Button>
          </Form.Item>
        </div>
      </Form>
      <Spin spinning={loading} fullscreen />
    </Modal>
  );
}
